import { graphql, useStaticQuery } from "gatsby";
import React from 'react';
import GatsbyImageAdaptor from "../../components/GatsbyImageAdaptor/GatsbyImageAdaptor";
import GCNResponsiveDesignVideo from "../../components/GCNResponsiveDesignVideo/GCNResponsiveDesignVideo";
import ProjectPage from '../../components/ProjectPage/ProjectPage';
import SimpleEntryGallery from "../../components/SimpleEntryGallery/SimpleEntryGallery";
import Spacer from "../../components/Spacer/Spacer";
// import './ProjectPageGCN.scss';
import { useGCNInfo } from '../../content/projects/gcn.project';
import { PageComponent } from '../../types/gatsby.types';
import { GcnAwardBadgeQuery } from "../../types/generated";

type ProjectPageGCNProps = {}

const ProjectPageGCN: PageComponent<ProjectPageGCNProps> = props => {
  const metaInfo = useGCNInfo();
  const GCNAwardBadge: GcnAwardBadgeQuery = useStaticQuery(graphql`
    query GCNAwardBadge {
      file(relativePath: {eq: "images/gcn/gcn-magazines-ireland-ima-2017-award-badge.png"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }`
  )
  return <ProjectPage
    {...props} // always include
    project={metaInfo}
    sidebarExtraContent={
      <GatsbyImageAdaptor
        image={GCNAwardBadge.file!.childImageSharp!} 
        alt="GCN: Winner of Irish Magazines Award 2017: Digital Product of the Year - Consumer Media" 
      />
    }
  >
    <SimpleEntryGallery entry={metaInfo} autoAddMobileSlides />
    <Spacer size="pageMargin" />
    <GCNResponsiveDesignVideo rounded />
  </ProjectPage>
}

export default ProjectPageGCN;